var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Oops!")]),
      _c("h3", [_vm._v("No encontramos lo que estas buscando..")]),
      _c(
        "router-link",
        { attrs: { replace: "", to: { name: "project-list" } } },
        [_vm._v("Volver a inicio")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }