<template>
  <div>
    <NotificationContainer />
    <DefaultLayout />
    <Loader />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DefaultLayout from '@/layouts/DefaultLayout';
import Loader from '@/components/Loader';
import NotificationContainer from '@/components/NotificationContainer';

export default {
  name: 'App',
  components: {
    DefaultLayout,
    Loader,
    NotificationContainer,
  },
  computed: {
    ...mapState('domain', ['config']),
  },
  async created() {
    await this.$store.dispatch('domain/fetchDomain', document.location.host);

    document.title = this.config.name
      ? `${this.config.name} - Timelapse México`
      : 'Timelapse México';
    document.documentElement.style.setProperty(
      '--primary',
      this.config ? this.config.color : '#4A62D4',
    );
  },
};
</script>
