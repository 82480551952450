import authService from '@/services/auth';
import apiClient from '@/services/api-client';
import axios from 'axios';
// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  token: null,
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_AUTH_DATA(state, token) {
    localStorage.setItem('token', token);
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  CLEAR_AUTH_DATA() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    location.reload();
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  login({ commit }, credentials) {
    return authService.login(credentials).then((token) => {
      commit('SET_AUTH_DATA', token);
      return token;
    });
  },
  logout({ commit }) {
    return authService.logout().then(() => {
      commit('CLEAR_AUTH_DATA');
    });
  },
  forgotPassword(context, data) {
    return authService.forgotPassword(data).then((res) => {
      return res.data.data;
    });
  },

  resetPassword(context, data) {
    return authService.resetPassword(data).then((res) => {
      return res.data.data;
    });
  },
  
  async getTokenHubZoho({ commit,dispatch }, data) {
    return axios.post('https://accounts.zoho.com/oauth/v2/token?client_id=1000.RL6SSG42HXOOMX38DQEKQSXIKWXPWW&client_secret=da5713c62b24598531558b0b884747326ef0754aeb&refresh_token=1000.b10ace351d18f4c452f65d656d3279a3.98a3edd23844b6303ba50334e574fcf8&grant_type=refresh_token')
    .then((res) => {
      return res.data;
    }).catch((error) => {
      dispatch('notification/addError', 'Error, cominicate con soporte o intentalo de nuevo', { root: true });
      throw error;
    });
  },

  async getDataHubZoho({ commit,dispatch }, data) {
    const config = {
      headers: { Authorization: `Bearer ${data.token}` }
    };
    const bodyParameters = { 
      key: "value"
    };

    return axios.post( 
      //'https://creator.zoho.com/api/v2/alfonso_filemakeradvisors/urbanhub/report/Listado_de_Usuarios?ID='+data.id_user,
      'https://proyectoshm.com/marco_pruebas/timelapse/zoho.php?id='+data.id_user+'&token='+data.token,
      bodyParameters,
      //config
    ).then((res) => {
      return res.data;
    }).catch((error) => {
      dispatch('notification/addError', 'Error, cominicate con soporte o intentalo de nuevo', { root: true });
      throw error;
    });
  }
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
