import apiClient from './api-client';
import dateUtil from '@/util/date';

export default {
  getCamera(id) {
    return apiClient.get(`/cameras/${id}`);
  },
  getCameraLibre(id) {
    return apiClient.get(`/cameras/${id}/libre`);
  },
  createVideo(cameraId, start, end, selectionStrategy, frameRate, quality) {
    return apiClient.post(`/cameras/${cameraId}/videos`, {
      start_date: dateUtil.startOfDay(start).toISOString(),
      end_date: dateUtil.endOfDay(end).toISOString(),
      selection_strategy: selectionStrategy,
      frame_rate: frameRate,
      quality,
    });
  },
};
