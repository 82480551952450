import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store';
import NotFound from '@/views/NotFound';
import NetworkIssue from '@/views/NetworkIssue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword'),
  },
  {
    path: '/camera/:id',
    name: 'camera-libre',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/CameraLibre'),
  },


  {
    path: '/cameras/:id/:esPublico?',
    props: true,
    component: () => import(/* webpackChunkName: "camera" */ '../views/Camera.vue'),
    children: [
      {
        path: '',
        name: 'camera-home',
        component: () =>
          import(/* webpackChunkName: "camera-home" */ '../views/CameraHome.vue'),
      },
      {
        path: 'compare',
        name: 'camera-compare',
        component: () =>
          import(/* webpackChunkName: "camera-compare" */ '../views/CameraCompare'),
      },
      {
        path: 'video',
        name: 'camera-video',
        props: true,
        component: () =>
          import(/* webpackChunkName: "camera-video" */ '../views/CameraVideo.vue'),
      },
    ],
  },

  
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'project-list',
        props: true,
        component: () => import(/* webpackChunkName: "project-list" */ '../views/ProjectList.vue'),
      },
      {
        path: '/projects/:id',
        name: 'project-home',
        props: true,
        component: () => import(/* webpackChunkName: "project-home" */ '../views/ProjectHome.vue'),
      },
      {
        path: '/videos/:id',
        name: 'video-player',
        props: true,
        component: () => import(/* webpackChunkName: "video-player" */ '../views/VideoPlayer.vue'),
      },
      
    ],
  },
  {
    path: '/profile/:project',
    props: true,
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile'),
    children: [
      {
        path: '',
        name: 'profile-home',
        props: true,
        component: () => import(/* webpackChunkName: "profile-home" */ '../views/ProfileHome'),
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/network-issue',
    name: 'network-issue',
    component: NetworkIssue,
  },
  {
    path: '*',
    redirect: { name: '404' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const openRoutes = ['login', 'forgot-password', 'reset-password',
'camera-libre', 'camera-home', 'camera-compare', 'camera-video'];

// ----------------------------------------------------------------------------
// GLOBAL ROUTE GUARDS
// ----------------------------------------------------------------------------
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');

  NProgress.start();
  store.dispatch('showLoader');

  if (openRoutes.includes(to.name)) {
    // if (to.name !== 'login' && !isAuthenticated) {
    //   return next({ name: 'login' });
    // }

    if (to.name === 'login' && isAuthenticated) {
      return next({ name: 'project-list' });
    }

    if (isAuthenticated) {
      await store.dispatch('user/fetchUser');
    }

    next();
  } else if (isAuthenticated) {
    if (isAuthenticated) {
      await store.dispatch('user/fetchUser');
    }

    next();
  } else {
    next({ name: 'login' });
  }
});

router.afterEach(() => {
  NProgress.done();
  store.dispatch('hideLoader');
});

export default router;
