var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100vh",
        "max-width": "100%",
        "max-height": "100%",
        display: "flex",
        "flex-direction": "column",
        overflow: "hidden",
        position: "relative"
      }
    },
    [
      _c(
        "section",
        { staticClass: "main-content" },
        [_vm._t("default", [_c("p", [_vm._v("Aqui va el contenido")])])],
        2
      ),
      _c(
        "section",
        {
          staticClass: "actionbar",
          class: {
            "actionbar--hidden": !_vm.actionbarVisible,
            "actionbar--iframe": _vm.esCamaraIframe
          }
        },
        [
          !_vm.esCamaraIframe
            ? _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end position-relative",
                  class: { "justify-content-end--iframe": _vm.esCamaraIframe },
                  style:
                    _vm.$route.name == "project-list"
                      ? "margin-bottom: -14px;"
                      : ""
                },
                [
                  _vm.session == true
                    ? _c("select-lenguage", {
                        on: { cambio: _vm.changeLenguage }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.esCamaraIframe
            ? _c(
                "button",
                {
                  staticClass: "actionbar__handle",
                  attrs: { tabindex: "-1", type: "button" },
                  on: {
                    click: function($event) {
                      _vm.actionbarVisible = !_vm.actionbarVisible
                    }
                  }
                },
                [
                  _vm.actionbarVisible
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("lang.hide.msg")))])
                    : _c("span", [_vm._v(_vm._s(_vm.$t("lang.show.msg")))])
                ]
              )
            : _vm._e(),
          _vm._t("actionbar", [
            _vm.session == true
              ? _c("p", [_vm._v("Aqui va el actionbar")])
              : _vm._e()
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }