import apiClient from './api-client';

export default {
  getPhotos(cameraId, startDate, endDate, selection, perPage, page, esPublico) {
    const pathPhotos = esPublico ? `/cameras/${cameraId}/photos/libre` : `/cameras/${cameraId}/photos`;
    return apiClient.get(pathPhotos, {
      params: {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        selection_strategy: selection,
        limit: perPage,
        page: page,
      },
    });
  },

  downloadPhotos(cameraId, { startDate, endDate, photoId }) {
    return apiClient.get(`/cameras/${cameraId}/photos/download`, {
      params: {
        photo_id: photoId,
        start_date: startDate ? startDate.toISOString() : null,
        end_date: endDate ? endDate.toISOString() : null,
      },
      responseType: 'blob',
    });
  },

  sendPhoto(photoId, subject, recipients, idioma) {
    //console.log("🚀 ~ file: photo.js ~ line 28 ~ sendPhoto ~ idioma", idioma)
    return apiClient.post(`/photos/${photoId}/sendnewz`, {
      subject,
      recipients,
      idioma
    });
  },
};
