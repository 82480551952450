import cameraService from '@/services/camera';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  cameras: [],
  camerasTotal: 0,
  camera: {},
  perPage: 5,
  videoSettings: {
    frameRate: 24,
    selectionStrategy: 'express',
    quality: 'original',
  },
  esCamaraIframe: false
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  ADD_CAMERA(state, camera) {
    state.cameras.push(camera);
  },
  SET_CAMERAS(state, cameras) {
    state.cameras = cameras;
  },
  SET_CAMERAS_TOTAL(state, camerasTotal) {
    state.camerasTotal = camerasTotal;
  },
  SET_CAMERA(state, camera) {
    state.camera = camera;
  },
  UPDATE_FRAME_RATE(state, frameRate) {
    state.videoSettings.frameRate = frameRate;
  },
  UPDATE_SELECTION_STRATEGY(state, selectionStrategy) {
    state.videoSettings.selectionStrategy = selectionStrategy;
  },
  SET_ES_CAMERA_IFRAME(state, esCamaraIframe) {
    state.esCamaraIframe = esCamaraIframe;
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  createCamera({ commit, dispatch }, camera) {
    return cameraService
      .createCamera(camera)
      .then(() => {
        commit('ADD_CAMERA', camera);
        commit('SET_CAMERA', camera);

        const notification = {
          type: 'success',
          message: 'La cámara fué creada!',
        };
        dispatch('notification/add', notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: 'error',
          message: 'Ocurrió un problema al crear la cámara: ' + error.message,
        };
        dispatch('notification/add', notification, { root: true });
        throw error;
      });
  },

  fetchCameras({ commit, dispatch, state }, { projectId, page }) {
    return cameraService
      .getCameras(projectId, state.perPage, page)
      .then((response) => {
        commit('SET_CAMERAS_TOTAL', parseInt(response.headers['x-total-count']));
        commit('SET_CAMERAS', response.data.cameras);
      })
      .catch((error) => {
        const notification = {
          type: 'error',
          message: 'Existe un problema al cargar las cámaras: ' + error.message,
        };
        dispatch('notification/add', notification, { root: true });
      });
  },

  fetchCamera({ commit, getters, state }, id) {
    if (id == state.camera.id) {
      return state.camera;
    }

    const camera = getters.getCameraById(id);

    if (camera) {
      commit('SET_CAMERA', camera);
      return camera;
    }

    return cameraService.getCamera(id).then((response) => {
      commit('SET_CAMERA', response.data.data);
      return response.data.data;
    });
  },

  fetchCameraLibre({ commit, getters, state }, id) {
    if (id == state.camera.id) {
      return state.camera;
    }

    const camera = getters.getCameraById(id);

    if (camera) {
      commit('SET_CAMERA', camera);
      return camera;
    }

    return cameraService.getCameraLibre(id).then((response) => {
      commit('SET_CAMERA', response.data.data);
      return response.data.data;
    });
  },

  selectCamera({ commit, state }, camera) {
    if (state.camera.id == camera.id) {
      return;
    }

    commit('SET_CAMERA', camera);
  },

  // eslint-disable-next-line no-unused-vars
  createVideo({ commit, state }, { camera, start, end, selectionStrategy, frameRate, quality }) {
    return cameraService
      .createVideo(camera.id, start, end, selectionStrategy, frameRate, quality)
      .then((response) => {
        console.log(response);
        return response.data;
      });
  },

  esCamaraIframe({ commit }, esIframe) {
    commit('SET_ES_CAMERA_IFRAME', esIframe);
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
export const getters = {
  getCameraById: (state) => (id) => {
    return state.cameras.find((camera) => camera.id === id);
  },
};
