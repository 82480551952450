import domainService from '@/services/domain';
import config from '@/config';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  config: {
    name: null,
    logo: config.TENANT_LOGO_PLACEHOLDER,
    color: null,
  },
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_DOMAIN_CONFIG(state, domainConfig) {
    state.config = { ...state.config, ...domainConfig };
    state.config.logo = domainConfig.logo ?? config.TENANT_LOGO_PLACEHOLDER;
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchDomain({ commit }, domain) {
    return domainService
      .getDomain(domain)
      .then((response) => {
        commit('SET_DOMAIN_CONFIG', response.data.data);

        return response.data.data;
      })
      .catch(() => {
        console.log('invalid domain');
      });
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
