import apiClient from './api-client';

export default {
  getUser() {
    return apiClient.get('/user');
  },

  updatePassword(userId, data) {
    data.append('_method', 'PATCH');
    return apiClient.post(`/user/${userId}/update-password`, data);
  },
};
