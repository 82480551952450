import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from './modules/auth';
import * as camera from './modules/camera';
import * as domain from './modules/domain';
import * as notification from './modules/notification';
import * as photo from './modules/photo';
import * as project from './modules/project';
import * as user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    camera,
    domain,
    notification,
    project,
    photo,
    user,
  },
  state: {
    showLoader: false,
  },
  mutations: {
    SHOW_LOADER(state) {
      state.showLoader = true;
    },
    HIDE_LOADER(state) {
      state.showLoader = false;
    },
  },
  actions: {
    showLoader({ commit }) {
      commit('SHOW_LOADER');
    },
    hideLoader({ commit }) {
      commit('HIDE_LOADER');
    },
  },
});
