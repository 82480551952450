<template>
  <div id="app" class="d-flex" :style="$route.name == 'camera-compare' ? `margin-bottom: 20px;` : ''">
      <p  class="mr-3">{{$t('lang.lng.msg')}}:</p> 
    <select v-model="$i18n.locale" @change="changeLenguage()">
      <option
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        :value="lang.value"
        
      >
        {{ lang.text }}
      </option>
    </select>
   
  </div>
</template>

<script>

export default {
  data() {
    return { 
      // creamos un array con los idiomas que tiene la aplicación
      langs: [{text:'English',value:'en'},{text:'Español', value:'es'}] 
    }
  },
  created() {
    console.log('$route.name', this.$route.name);
  },
  methods:{
      changeLenguage(){
          console.log(this.$i18n.locale,"idioma");
          
          if(this.$i18n.locale == 'es'){
              this.$emit('cambio', true);
          }else{
              this.$emit('cambio', false);
          }
      }
  }
}
</script>