import Vue from 'vue';
import './plugins/bootstrap-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import AppLayout from '@/layouts/AppLayout';
//Importamos la librería y nuestros dos json
import VueI18n from 'vue-i18n';
import en from './lang/en_US'
import es from './lang/es_ES'

Vue.config.productionTip = false;

Vue.component('AppLayout', AppLayout);

Vue.filter('kb_to_gb', (value) => {
  return Math.round((value / (1024 * 1024)) * 100) / 100;
});

//añadimos la libreria
Vue.use(VueI18n);
var userLanguage = ((navigator.languages && navigator.languages[0]) || '').substr(0, 2);
console.log("🚀 ~ file: main.js ~ line 24 ~ userLanguage", userLanguage)
if(userLanguage != 'en' && userLanguage != 'es'){
  userLanguage= 'es';
}
const i18n = new VueI18n({
  locale: userLanguage,
  messages: {
    en: {
      lang: en
    },
    es: {
      lang: es
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n
}).$mount('#app');
