<template>
  <b-toast
    :id="`notification-${notification.id}`"
    :variant="notificationTypeClass"
    visible
    solid
    append-toast
    no-close-button
    :toaster="notificationTypePosition"
    @hidden="remove(notification)"
  >
    {{ notification.message }}
  </b-toast>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NotificationBar',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      typeMap: {
        error: 'danger',
        success: 'success',
      },
      position: {
        top: 'b-toaster-top-center',
        bottom: 'b-toaster-bottom-center'
      }
    };
  },
  computed: {
    notificationTypeClass() {
      return this.typeMap[this.notification.type];
    },
    notificationTypePosition(){
      return this.position[this.notification.position] ||  this.position.bottom;
    }
  },
  methods: mapActions('notification', ['remove']),
};
</script>
