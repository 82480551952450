import config from '@/config';
import userService from '@/services/user';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  user: null,
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    localStorage.setItem('user', JSON.stringify(userData));
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchUser({ commit, state }) {
    if (state.user) {
      return state.user;
    }

    return userService.getUser().then((response) => {
      commit('SET_USER_DATA', response.data.data);
      return response;
    });
  },

  updatePassword({ commit }, { userId, data }) {
    return userService.updatePassword(userId, data).then((res) => {
      commit('SET_USER_DATA', res.data.data);
      return res.data.data;
    });
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
export const getters = {
  tenantLogoUrl: (state) => state.user.tenant?.logo_url ?? config.TENANT_LOGO_PLACEHOLDER,
  tenantName: (state) => state.user.tenant?.name ?? '',
};
