<template>
  <div class="tl-loader" v-show="showLoader"></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Loader',
  computed: mapState(['showLoader']),
};
</script>

<style lang="sass" scoped>
.tl-loader
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: #fbfbfb
  background-image: url('../assets/loader.gif')
  background-repeat: no-repeat
  background-position: center
  z-index: 10
</style>
