var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex",
      style: _vm.$route.name == "camera-compare" ? "margin-bottom: 20px;" : "",
      attrs: { id: "app" }
    },
    [
      _c("p", { staticClass: "mr-3" }, [
        _vm._v(_vm._s(_vm.$t("lang.lng.msg")) + ":")
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$i18n.locale,
              expression: "$i18n.locale"
            }
          ],
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.$i18n,
                  "locale",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              function($event) {
                return _vm.changeLenguage()
              }
            ]
          }
        },
        _vm._l(_vm.langs, function(lang, i) {
          return _c(
            "option",
            { key: "lang-" + i, domProps: { value: lang.value } },
            [_vm._v(" " + _vm._s(lang.text) + " ")]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }