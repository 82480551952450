import photoService from '@/services/photo';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  photos: [],
  photosTotal: 0,
  photo: null,
  perPage: 99999,
  filterDate: new Date(),
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_PHOTOS(state, photos) {
    state.photos = photos;
  },
  SET_PHOTOS_TOTAL(state, photosTotal) {
    state.photosTotal = photosTotal;
  },
  SET_PHOTO(state, photo) {
    state.photo = photo;
  },
  SET_FILTER_DATE(state, date) {
    state.filterDate = date;
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchPhotos(
    { dispatch, state, commit },
    { camera, startDate, endDate, selectionStrategy = 'all', page = 1, esPublico=false },
  ) {


    return photoService
      .getPhotos(camera.id, startDate, endDate, selectionStrategy, state.perPage, page, esPublico)
      .then((response) => {
        commit('SET_PHOTOS', response.data.data)
        return response.data.data;
      })
      .catch((error) => {
        console.error(error);

        let message = error.message;

        if (error.response && error.response.data) {
          message = error.response.data.message;
        }

        const notification = {
          type: 'error',
          message: 'Existe un problema al cargar las fotografías: ' + message,
        };

        dispatch('notification/add', notification, { root: true });

        throw error;
      });


  },

  selectPhoto({ commit, state }, photo) {
    if (state.photo?.id === photo?.id) {
      return;
    }
    commit('SET_PHOTO', photo);
  },

  setFilterDate({ commit }, newDate) {
    commit('SET_FILTER_DATE', newDate);
  },

  download(context, { camera, startDate, endDate, photo }) {
    return photoService.downloadPhotos(camera.id, {
      startDate,
      endDate,
      photoId: photo?.id,
    });
  },

  sendPhoto(context, { photo, subject, recipients, idioma }) {
    return photoService.sendPhoto(photo.id, subject, recipients, idioma);
  },
};
