//import config from '@/config';
import apiClient from './api-client';

export default {
  login(credentials) {
    return apiClient
      .post('/tokens', {
        ...credentials,
        device_name: 'timepse-api',
      })
      .then((response) => {
        return response.data;
      });
  },
  logout() {
    return apiClient.get('/logout');
  },
  forgotPassword(data) {
    return apiClient.post(`/forgot-password`, data);
  },

  resetPassword(data) {
    return apiClient.post(`/reset-password`, data);
  },
};
