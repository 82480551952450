var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-toast",
    {
      attrs: {
        id: "notification-" + _vm.notification.id,
        variant: _vm.notificationTypeClass,
        visible: "",
        solid: "",
        "append-toast": "",
        "no-close-button": "",
        toaster: _vm.notificationTypePosition
      },
      on: {
        hidden: function($event) {
          return _vm.remove(_vm.notification)
        }
      }
    },
    [_vm._v(" " + _vm._s(_vm.notification.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }