import axios from 'axios';
import config from '@/config';
import store from '@/store';

const apiClient = new axios.create({
  baseURL: config.API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 600000,
});

const token = localStorage.getItem('token');
//console.log("🚀 ~ lslslslsls<1: ", token)

if (token) {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error?.response?.status;
    const errorCode = error.response?.data?.error?.code;

    if (statusCode === 401) {
      //store.dispatch('auth/logout');
      window.location.replace("/");
    }

    if (statusCode === 403 && errorCode === 'API-INACTIVE-USER') {
      store.dispatch('auth/logout');
      //window.location.replace(".");
    }

    return Promise.reject(error);
  },
);

export { apiClient as default };
